import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../components/Layout";
import Reviews from "../../components/Reviews";
import JumbotronZaveti from "../../components/zaveti/JumbotronZaveti";
import ProuzekTelefon from "../../components/ProuzekTelefon";
import Podpora from "../../components/zaveti/Podpora";
import ZavetCTA from "../../components/zaveti/ZavetCTA";
import Img from "gatsby-image"

import styled from "styled-components";
import FAQ from "../../components/FrequentlyAskedQ";
import Breadscrum from "../../components/Breadscrum";
import JumbotronV2 from "../../components/brandingV2/JumbotronV2";
import LinkButtonV2 from "../../components/brandingV2/LinkButtonV2";
import Gdpr from "../../assets/img/gdpr.svg";
import Proces1 from "../../assets/img/willProces1.svg";
import Proces2 from "../../assets/img/willProces2.svg";
import Proces3 from "../../assets/img/willProces3.svg";
import Proces4 from "../../assets/img/willProces4.svg";
import Privacy from "../../assets/img/privacy.svg";
import willFeature1 from "../../assets/img/willFeature1.svg";
import willFeature2 from "../../assets/img/willFeature2.svg";
import willFeature3 from "../../assets/img/willFeature3.svg";
import willFeature4 from "../../assets/img/willFeature4.svg";
import willFeature5 from "../../assets/img/willFeature5.svg";
import willFeature6 from "../../assets/img/willFeature6.svg";
import willFinalCta from "../../assets/img/willFinalCta.svg";
// import Proces4 from "../../assets/img/willProces4.svg";
import Czechpoint from "../../assets/img/czechpoint.svg";
import RecenzeV2 from "../../components/brandingV2/RecenzeV2";
import WillInfo from "../../components/zavetiV2/willInfo";
import Proces from "../../components/brandingV2/Proces";
import BlogPreview from "../../components/brandingV2/BlogPreview";
import { useMediaQuery } from "react-responsive";
import CallButtonV2 from "../../components/brandingV2/CallButtonV2";
import NonprofitSmall from "../../components/brandingV2/NonprofitSmall";
import FinalCta from "../../components/brandingV2/FinalCta";



const faqData = [
  {
    otazka: "Co se stane, pokud zemřu bez závěti?",
    odpoved: "Pokud někdo zemře bez závěti (nebo dědické smlouvy), jejich majetek se rozdělí podle zákonné posloupnosti příslušným dědicům (jeho rodině). Jedná se o 6 tříd příbuzných zůstavitele od nejbližších (manžel/manželka a potomci) až po vzdálenější (praneteře a prasynovci, tety a strýcové)."
  },
  {
    otazka: "Jak je možné, že lze napsat závěť online?",
    odpoved: "Pokud jsou vaše přání relativně jednoduchá, není potřeba notáře k sepsání vaší závěti. Co například přes internet udělat nejde, je vydědit potomka - k tomu je právě potřeba notářský zápis. Každopádně na 99% všech ostatních věcí, které s námi lidé běžně řeší, notáře nepotřebujete.<br><br>K tomu, aby vaše závěť potom nabyla právní moci, je potřeba ji vytisknout a podepsat společně s dvěma svědky. Vytvoření závěti na internetu je naprosto v pořádku. Každou závěť vám navíc ještě před odesláním překontroluje náš expert - nemusíte se tak vůbec ničeho bát."
  },
  {
    otazka: "Jak podepsat závěť, aby byla platná?",
    odpoved: "Aby byla závěť právoplatná, je třeba si vybrat dva svědky, kteří nejsou v závěti zahrnutí a nejsou nijak příbuzní vám ani dědicům zmiňovaným v závěti (manžel/ka, zaměstnanec...). U podpisu musí být všichni 3 - sepisovatel poslední vůle i oba svědkové - fyzicky přítomni.<br><br>Sepisovatel závěti nejdříve nahlas prohlásí, že se jedná o jeho závěť. Poté ji jako první podepíše. Následně ji podepíší oba svědkové.<br><br>Je důležité, aby všechny osobní informace v závěti byly čitelné, a abyste nic neškrtali."
  },
  {
    otazka: "Jak je to s dědickou daní?",
    odpoved: "V roce 2014 byla v českém právním řádu zrušena dědická daň. To znamená, že všechny příjmy dědiců a odkazovníků z pozůstalosti jsou od daně osvobozeny (a to i v případě právnických osob). Jediné, co se ale platit musí, jsou poplatky soudnímu komisaři, který má na starosti dědické řízení. Výše poplatku se potom odvíjí od hodnoty majetku, který je předmětem dědictví."
  },
  {
    otazka: "Kolik to stojí peněz?",
    odpoved: 'Zajímá vás aktuální cena našich služeb? Podívejte se do našeho ceníku. Obecně se platí jednorázový poplatek po dokončení tvorby vaší závěti. Na tento poplatek můžete dostat různé slevy (pokud si například sepíšete závěť s partnerem). Na první rok také k závěti dostanete zdarma naši službu pro neomezené aktualizace dokumentu. To znamená, že pokud by se ve vašem životě něco změnilo, můžete u nás závěť vždy zpětně upravit. Tato služba dále stojí 190 Kč ročně. První rok je zdarma.'
  },
  {
    otazka: 'Co to je závěť "pro páry"?',
    odpoved: 'To je pouze sleva na to, pokud byste se s rozhodli s partnerem oba napsat závěť s námi. Ihned po zaplacení vaší závěti tak dostanete slevový kupón, který váš partner může uplatnit jako slevu u své objednávky.'
  },
  {
    otazka: 'Můžete vidět, co jsem do své závěti napsal/a?',
    odpoved: 'Ano. To je asi ten nejdůležitější detail, který nás liší od konkurence. Ke každé závěti totiž přistupujeme naprosto inviduálně, kontrolujeme ji a hlavně přemýšlíme předem "kde by v budoucnu mohl nastat problém". Pokud se nám něco nezdá, odkážeme vás raději na partnerskou advokátní kancelář a vrátíme vám všechny peníze. To je podle nás ta jediná cesta, jak našim klientům dodat ten nejlepší právní dokument.<br><br>vaše data jsou samozřejmě maximálně v bezpečí. S nikým je nesdílíme. Nikomu je neukazujeme. Vše je také důkladně šifrované podle protokolu HTTPS.'
  },
];

const recenze = [
  {
    recenze: "Vše proběhlo rychle, srozumitelný formulář, příjemné jednání.",
    jmeno: "Zita J.",
    mesto: "Praha",
  },
  {
    recenze: "Děkuji za pomoc. Celý proces byl rychlý, profesionální - doporučuji dál!",
    jmeno: "Jitka",
    mesto: "Brno",
  },
  {
    recenze: "Velice jednoduchý nástroj na tvorbu závětí. Cena v pořádku.",
    jmeno: "Vladimír M.",
    mesto: "Praha",
  },
  {
    recenze: "Líbí se mi, že si závěť můžu udělat v klidu doma a nemusím kvůli tomu nikam jezdit.",
    jmeno: "Alena",
    mesto: "Ústí nad Labem",
  },
  {
    recenze: "Vše bylo velmi dobře vysvětlené a snadné... Za večer jsem měla hotovo.",
    jmeno: "Kateřina",
    mesto: "Liberec",
  },
  {
    recenze: "Se společností a rychlostí vyhotovení závěti jsem nadmíru spokojena.",
    jmeno: "Lucie",
    mesto: "Praha",
  },
  {
    recenze: "Opravdu perfektní služba, jen tak dál!",
    jmeno: "Jiří",
    mesto: "Plzeň",
  },
  {
    recenze: "Jednoduche vysvetleni, rychle vyplneni a zpetna vazba. Doporucuji vsem si zavet touto cestou udelat a zajistit klid sobe i pripadnym dedicum.",
    jmeno: "Petr",
    mesto: "Brno",
  },
  {
    recenze: "Moc mi vaše služba pomohla vyřešit narychlo závěť před dovolenou.",
    jmeno: "Anna",
    mesto: "Kladno",
  },
];

let structuredData = {
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "Závěť Online",
  "brand":"Goodbye.cz",
  "image":"https://goodbye.cz/zavet.jpg",
  "description": "Nejpoužívanější služba na online závěti v ČR za 2 190 Kč. Vytvořeno advokáty. Napište plnohodnotnou poslední vůli přes internet. Postarejte se o své nejbližší.",
  "aggregateRating": {
    "@type":"AggregateRating",
    "bestRating": "5",
    "ratingCount": 269,
    "ratingValue": 4.93,
    "worstRating": "1"}
};

const ZavetiInner = styled.div`
  .nowrap {
    white-space: nowrap;
    font-family: inherit;
  }

  .leftJumbo {
    max-width: 670px !important;

    h1 {
      line-height: 60px;

      @media (max-width: 960px) {
        font-size: 39px !important;
        line-height: 50px;
      }

      @media (max-width: 550px) {
        font-size: 33px !important;
        line-height: 44px;
        margin-bottom: 20px !important;
      }

      @media (max-width: 460px) {
        font-size: 28px !important;
        line-height: 39px;
      }

      @media (max-width: 430px) {
        margin-top: 10px !important;
      }
  


    }

    .desc {
      font-size: 20px !important;
      line-height: 30px !important;

      @media (max-width: 430px) {
        font-size: 16px !important;
        line-height: 24px !important;
        margin-bottom: 30px !important;
      }
    }

    .linkButton {
      @media (max-width: 660px) {
        margin-right: 20px !important;
      }

      @media (max-width: 360px) {
        margin-right: 0 !important;
      }
    }

    img {
      &:nth-child(2) {
        margin-right: 20px;
      }
    }

    .buttons {
      margin-bottom: 40px !important;

      @media (max-width: 1005px) {
        display: flex !important;

        div {
          margin-bottom: 0 !important;
        }
      }

      @media (max-width: 899px) {
        display: flex !important;
      }

      @media (max-width: 530px) {
        display: block !important;
        margin-bottom: 40px !important;

        img {
          vertical-align: middle;

          &:nth-child(2) {
            /* margin-top: 15px; */
          }
        }

        div {
          margin-bottom: 15px !important;
        }
      }
    }

    .rating {
      p {
        @media (max-width: 350px) {
          font-size: 16px;
        }
      }
    }
  }

  .rightJumbo {
    .obrazek {
      margin: -50px auto;

      @media (max-width: 1130px) {
        height: 290px !important;
        width: 300px !important;
      }

      @media (max-width: 899px) {
        height: 170px !important;
        width: 300px !important;
        margin: 0;
        margin-bottom: 30px;
      }

      @media (max-width: 450px) {
        height: 130px !important;
        width: 220px !important;
        margin-bottom: 20px;
        margin-top: -10px;
      }
    }
  }

  .simpleSec {
    width: calc(100% - 100px);
    max-width: 1150px;
    margin: 70px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 899px) {
      max-width: 700px;
      width: calc(100% - 50px);
    }

    @media (max-width: 600px) {
      margin: 50px auto;
    }

    @media (max-width: 630px) {
      display: block;
    }

    h2 {
      font-family: NewKansas;
      font-size: 36px;
      color: #2c4668;

      @media (max-width: 899px) {
        font-size: 32px;
        line-height: 40px;
      }

      @media (max-width: 430px) {
        font-size: 28px;
        line-height: 35px;
      }
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 18px;
      color: #394B5C;
      line-height: 28px;
      margin-top: 30px;

      a {
        color: #394B5C;
      }

      @media (max-width: 430px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .left {
      width: calc(100% - 250px - 70px);
      max-width: 700px;

      @media (max-width: 950px) {
        width: calc(100% - 200px - 50px);
      }

      @media (max-width: 630px) {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }

  .privacy {
    padding-bottom: 70px;
    border-bottom: 1px solid #B2B2B2;

    @media (max-width: 630px) {
      padding-bottom: 50px;
      margin-bottom: -20px;
    }

    .privacyPic {
      width: 250px;
      
      @media (max-width: 950px) {
        width: 200px;
      }
    }
  }

  .experts {
    p {
      margin-bottom: 25px;
    }

    .obrazek {
      width: 2500px;

      @media (max-width: 950px) {
        width: 200px !important;
        height: 118px !important;
      }
    }
  }

  .sixFeatures {
    width: calc(100% - 100px);
    max-width: 1150px;
    margin: 70px auto;

    @media (max-width: 899px) {
      max-width: 700px;
      width: calc(100% - 50px);
    }

    @media (max-width: 600px) {
      margin: 50px auto;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 60px;

      @media (max-width: 1000px) {
        grid-column-gap: 30px;
        grid-row-gap: 50px;
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 600px) {
        grid-row-gap: 50px;
        grid-template-columns: repeat(1, 1fr);
      }

      li {
        list-style: none;

        img {
          opacity: 0.4;
        }

        h3 {
          font-family: Visuelt-Medium;
          font-size: 24px;
          color: #2C4668;
          margin-bottom: 10px;
          margin-top: 15px;

          @media (max-width: 430px) {
            font-size: 21px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 18px;
          line-height: 28px;
          color: #44596D;

          @media (max-width: 430px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .appOverview {
    background: #F4F3F0;
    border-radius: 20px;
    max-width: 1280px;
    margin: 70px auto;
    width: calc(100% - 100px);
    padding: 60px 0;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
    }

    @media (max-width: 600px) {
      width: calc(100% - 20px);
      padding: 40px 0;
      margin: 50px auto;
    }

    .wrap {
      width: calc(100% - 100px);
      max-width: calc(1150px);
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 899px) {
        display: block;
        width: calc(100% - 50px);
      }

      .left {
        width: calc(100% - 400px - 70px);
        
        @media (max-width: 1240px) {
          width: calc(100% - 300px - 40px);
        }

        @media (max-width: 950px) {
          width: calc(100% - 250px - 40px);
        }

        @media (max-width: 899px) {
          width: 100%;
          text-align: center;
        }

        .linkButton {
          a {
            @media (max-width: 899px) {
              display: block;
              margin: 0 auto;
            }
          }
        }

        h2 {
          font-family: NewKansas;
          font-size: 36px;
          color: #2c4668;
          line-height: 50px;

          @media (max-width: 899px) {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 25px;
            text-align: center;
          }

          @media (max-width: 430px) {
            font-size: 28px;
            line-height: 35px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 18px;
          color: #394B5C;
          line-height: 28px;
          margin-top: 30px;

          a {
            color: #394B5C;
          }

          @media (max-width: 899px) {
            text-align: center;
          }

          @media (max-width: 430px) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        span {
          font-family: Visuelt-Bold;
          font-size: 18px;
          color: #0055a5;
          margin-top: 30px;
          margin-bottom: 30px;
          display: block;
          text-transform: uppercase;

          @media (max-width: 430px) {
            font-size: 16px;
            line-height: 23px;
          }
        }        
      }

      .right {

        .obrazek {
          width: 400px !important;

          @media (max-width: 1240px) {
            width: 300px !important;
            height: 172px !important;
          }

          @media (max-width: 950px) {
            width: 250px !important;
            height: 145px !important;
          }

          @media (max-width: 899px) {
            width: 400px !important;
            height: 240px !important;
            display: block !important;
            margin: 0 auto !important;
            margin-top: 40px !important;
          }

          @media (max-width: 460px) {
            width: 250px !important;
            height: 145px !important;
          }
        }
        
      }
    }
  }

  .blogPreview {
    background: #F4F3F0;
    padding: 60px 0;
    margin: 70px auto;

    @media (max-width: 600px) {
      margin: 50px auto;
      padding: 40px 0;
    }

    .wrap {
      margin: 0 auto;
    }
  }
`;

const Zaveti = ({ data }) => {
  const isLess500 = useMediaQuery({
    query: '(max-width: 500px)'
  });

  return (
    <Layout
      customCtaText={"Zkusit ZDARMA"}
      customCtaLink={"https://zaveti.goodbye.cz/registrace"}
      customLoginText={"Přihlásit se"}
      customLoginLink={"https://zaveti.goodbye.cz/prihlaseni"}
      subMenuItems={[
        {
          name: "Závěti",
          url: "/zaveti/",
        },
        {
          name: "Digitální archiv",
          url: "/digitalni-archiv/",
        },
        {
          name: "Předplacený pohřeb",
          url: "/predplaceny-pohreb/",
        },
      ]}
    >
      <SEO
        title="Sepište platnou závěť za 20 minut, online, bez notáře | Goodbye"
        customTitle
        description="Nejpoužívanější služba na online závěti v ČR za 2 190 Kč. Vytvořeno advokáty. Napište plnohodnotnou poslední vůli přes internet. Postarejte se o své nejbližší."
        image="/zavetiOg.png"
      />

      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}}/>

      <ZavetiInner>
        <JumbotronV2
          noSplatky
          jumboObrazek={data.jumboObrazek.childImageSharp.fixed}
          jumboObrazekMobile={data.jumboObrazekMobile.childImageSharp.fixed}
          h1={"Nejpoužívanější služba na digitální závěti v Čechách"}
          hideSecondHeading
          p={<>Sepište plnohodnotnou závěť přes internet a postarejte se o své nejbližší. Hotovo máte do 20 minut za pouhých <span className="nowrap">2 190 Kč</span>.</>}
          reviewDesc={<>Stovky spokojených zákazníků <span className="nowrap">(4,95/5 průměrné hodnocení)</span></>}
          breadscrum={
            <Breadscrum
              visible={true}
              stranky={[
                {name: "Domů", url: "/"},
                {name: "Závěti", url: "/zaveti/"}
              ]}
            />
          }
          customCta={[
            <LinkButtonV2 to="https://zaveti.goodbye.cz/registrace" arrow text="Začněte zdarma" />,
            <img src={Gdpr} alt="V souladu s GDPR" />,
            <img src={Czechpoint} alt="CzechPoint logo" />,
          ]}
          botCtaText={"Závěť lze vytvořit zdarma, bez kreditní karty. Platíte až těsně před tiskem."}
        />


        <RecenzeV2 
          customReviews={recenze}
          sklonovaneMesto={""}
          customH2={"Váš důvěryhodný partner při plánování pozůstalosti"}
        />

        <WillInfo />

        <Proces 
          h2="Jak to funguje?"
          procesDesc={"Dnes se už závěť nemusí složitě sepisovat formou notářské listiny. Mnohem modernější a časově úspornější způsob je tvorba alografní listiny přes internet. Účinnost poslední vůle je stejná, vše zařídíte z domova a hotový dokument vám pošleme do 48 hodin."}
          cutomTabs={[
            {
              num: 1,
              pic: Proces1,
              heading: "Odpovězte nám na pár jednoduchých otázek",
              desc: "Díky naší webové aplikaci je tvorba závěti opravdu jednoduchá. Zvládnete to lehce i do 20 minut z pohodlí domova.",
            },
            {
              num: 2,
              pic: Proces2,
              heading: "Závěť vám zkontrolujeme",
              desc: "Zkontrolujeme, zda-li vše ve vaší závěti dává smysl a jestli nerozporuje se zákonem. Celý proces trvá max. 2 pracovní dny.",
            },
            {
              num: 3,
              pic: Proces3,
              heading: "Vytisknout a podepsat",
              desc: "Jako poslední krok musíte již opravený dokument vytisknout a podepsat před dvěma svědky. Tím nabere účinnosti.",
            },
            {
              num: 4,
              pic: Proces4,
              heading: "Uschovat závěť",
              desc: <>Podepsanou závěť uložte na bezpečném místě nebo <Link to="/digitalni-archiv/">digitálním archivu</Link>, kde ji vaší blízcí jednoduše najdou v případě potřeby.</>,
            },
          ]}
        />

        <div className="simpleSec privacy">
          <div className="left">
            <h2>Vaše soukromí na prvním místě</h2>
            <p>Uvědomujeme si, že psaní závěti je pro většinu velmi osobní věc. Na našich stránkách tak používáme to nejmodernější zabezpečení a šifrování dat v souladu s GDPR. Vaše informace tak zůstanou v naprostém bezpečí.</p>
          </div>
          <img className="privacyPic" src={Privacy} alt="Vaše soukromí na prvním místě" />
        </div>

        <div className="simpleSec experts">
          <div className="left">
            <h2>Naši specialisté jsou vám k dispozici</h2>
            <p>Jsme tu pro vás 7 dní v týdnu. Ať už máte otázku, co se týče tvorby samotné závěti nebo nějaké právní nejasnosti - stačí jen zavolat nebo napsat na <a href="mailto:podpora@goodbye.cz">podpora@goodbye.cz</a> a my vám vše vysvětlíme.</p>
            <CallButtonV2 />
          </div>
          {isLess500 ?
            <Img className="obrazek" alt="Naše podpora" fixed={data.smallTeam.childImageSharp.fixed} />
          :
            <Img className="obrazek" alt="Naše podpora" fixed={data.bigTeam.childImageSharp.fixed} />
          }
        </div>
        
        <div className="appOverview">
          <div className="wrap">
            <div className="left">
              <h2>Vaší závěť můžete kdykoliv měnit. My se postaráme o zbytek.</h2>
              <p>Život nás všech se mění každým dnem. Vaše závěť by měla tyto změny reflektovat. Každou úpravu zaznamenejte do naší přehledné aplikace a my vám na jejím základě vždy pošleme nový dokument.</p>
              <span>PRVNÍ ROK ČLENSTVÍ V CENĚ, POTÉ 190 Kč za rok</span>
              <LinkButtonV2 to="https://zaveti.goodbye.cz/registrace" text="Začít plánovat" />
            </div>
            <div className="right">
              <Img className="obrazek" alt="Závěti online" fixed={data.willsMac.childImageSharp.fixed} />
            </div>
          </div>
        </div>

        <NonprofitSmall />


        <div className="sixFeatures">
          <ul>
            <li>
              <img src={willFeature1} alt="" />
              <h3>Připravte se</h3>
              <p>Nastavte jasné instrukce podle svých přání a předejděte tak všem nejasnostem pro své nejbližší.</p>
            </li>
            <li>
              <img src={willFeature2} alt="" />
              <h3>Udělejte si pořádek</h3>
              <p>Vytvořte si kompletní soupis svých informací a majetku. Nic tak nenechte na poslední chvíli.</p>
            </li>
            <li>
              <img src={willFeature3} alt="" />
              <h3>Dejte vědět blízkým</h3>
              <p>Sdílejte svůj plán se svou rodinou a přáteli. Je to téma, které jednou bude řešit každý.</p>
            </li>
            <li>
              <img src={willFeature4} alt="" />
              <h3>Buďte si jistí</h3>
              <p>Díky našemu pokročilému zabezpečení a šifrování si můžete být jistí, že jsou vaše data v bezpečí.</p>
            </li>
            <li>
              <img src={willFeature5} alt="" />
              <h3>Nechte si poradit</h3>
              <p>Nespoléhejte se na googlování nebo rady přátel - naše podpora vám zodpoví všechny vaše otázky.</p>
            </li>
            <li>
              <img src={willFeature6} alt="" />
              <h3>Oddechněte si</h3>
              <p>Jste připraveni závěť konečně pustit z hlavy? S naší intuitivní aplikací máte za 20 minut hotovo.</p>
            </li>
          </ul>
        </div>

        

        <BlogPreview wills />

        <FAQ data={faqData} />

        <FinalCta
          image={willFinalCta}
          imageAlt="Poslední vůle"
          h2="Začněte psát svou závěť ještě dnes"
          p="Vše si připravte nanečisto. Platba až před tiskem. Zítra máte hotovo."
          ctaLink="https://zaveti.goodbye.cz/registrace"
          ctaText="Začít psát závěť"
        />

      </ZavetiInner>
    </Layout>
  );
}

export const query = graphql`
  query {
    jumboObrazek: file(relativePath: { eq: "willsJumboPic.png" }) {
      childImageSharp {
        fixed(width: 450) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jumboObrazekMobile: file(relativePath: { eq: "willsJumboPicSmall.png" }) {
      childImageSharp {
        fixed(width: 315) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    willsMac: file(relativePath: { eq: "willsMac.png" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    bigTeam: file(relativePath: { eq: "podpora.png" }) {
      childImageSharp {
        fixed(width: 250, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    smallTeam: file(relativePath: { eq: "podpora.png" }) {
      childImageSharp {
        fixed(width: 160, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

export default Zaveti;


